<template>
  <SiteHeader
    :username="tbcoUserView ? tbcoUserView.username : undefined"
    :displayName="tbcoUserView ? tbcoUserView.trimmed_name : undefined"
    :loggedIn="tbcoUserView ? true : false"
    :whatsNewCount="tbcoUserView ? tbcoUserView.whats_new_count : undefined"
    :canOptIntoTrial="false"
    :optedIntoTrial="tbcoUserView ? tbcoUserView.show_responsive_pages : undefined"
    :userProfileImage="tbcoUserView ? tbcoUserView.user_profile_image : undefined"
    :orderMember="isOrderMember"
    :siteAdmin="isSiteAdmin"
    :sliderEdit="sliderEdit"
    :futureDharmaDonation="futureDharmaDonation"
    :bannerTitle="bannerTitle"
    :bannerSubtitle="bannerSubtitle"
    :toggleLabel="toggleLabel"
    :toggleLabelExpanded="toggleLabelExpanded"
    :headerBannerVideo="headerBannerVideo"
    :siteHeaderMenu="siteHeaderMenu"
    :showBanner="showBanner"
    @donate="donate"
    :userMenu="userMenu"
    :loginUrl="loginUrl"

    :showDonateBtn="showDonateBtn"
    :leftLinkText="leftLinkText"
    :headerBannerLink="headerBannerLink"
    :rightBannerLink="rightBannerLink"
    :rightBannerLinkText="rightBannerLinkText"
  />
</template>

<script>
import SiteHeader from '@dharmachakra/tbco-site-header/src/components/SiteHeader.vue'
import SiteHeaderMenuApi from '@dharmachakra/tbco-site-header/src/api/SiteHeaderMenuApi.js'

export default {
  components: {
    SiteHeader
  },
  data () {
    return {
      tbcoUserView: null,
      isOrderMember: false,
      isSiteAdmin: false,
      futureDharmaDonation: false,
      showBanner: false,
      headerBannerVideo: false,
      sliderEdit: false,
      toggleLabel: ''
    }
  },
  computed: {
    loginUrl() {
      return '/user/login?destination=' + window.location.pathname + window.location.search
    },
    siteHeaderMenu () {
      return SiteHeaderMenuApi.store.siteHeaderMenu
    },
    userMenu() {
      const username = this.tbcoUserView ? this.tbcoUserView.username : undefined
      const canAccessNbco = this.tbcoUserView ? this.tbcoUserView.can_access_nbco : false
      const nbcoEnabled = this.tbcoUserView ? this.tbcoUserView.nbco_enabled : false
      const whatsNewCount = this.tbcoUserView ? parseInt(this.tbcoUserView.whats_new_count) : undefined

      let items = []

      if (canAccessNbco) {
        if (nbcoEnabled) {
          items.push({
            link: '/toggle-new-bco?returnTo=' + encodeURIComponent(window.location.href),
            label: 'turn off new buddhist centre online',
            classes: ['admin-control']
          })
        } else {
          items.push({
            link: '/api/v1/display-nbco?returnTo=' + encodeURIComponent(window.location.href),
            label: 'display new buddhist centre online',
            classes: ['admin-control']
          })
        }
      }

      items.push({
        link: '/users/' + username + '/edit',
        label: 'my profile'
      })

      if (this.orderMember) {
        items.push({
          link: '/users/' + username + '/order_edit?destination=users/' + username + '/order',
          label: 'edit my order account',
          classes: ['edit-order-info']
        })
      }

      items = items.concat([{
        link: '/users/' + username + '/edit',
        label: 'add/edit my picture'

      },
      {
        link: '/mysangha/new',
        label: 'my sangha ' + ((whatsNewCount > 0) ? '(' + whatsNewCount + ')' : ''),
        classes: ['my-sangha']
      },
      {
        link: '/projects/my_projects_groups/list/az',
        label: 'spaces I follow'
      },
      {
        link: '/users/' + username + '/my_posts',
        label: 'my posts'
      }
      ])

      if (this.isSiteAdmin && this.sliderEdit) {
        items.push({
          link: '/home-sliders/home-page-items-edit',
          label: 'edit home sliders',
          classes: ['admin-control']
        })
        items.push({
          link: '/node/add/home-media-panel',
          label: 'add to home page',
          classes: ['admin-control']
        })
      }

      if (this.isSiteAdmin) {
        items.push({
          link: '/admin/',
          label: 'drupal admin',
          classes: ['admin-control']
        })
        items.push({
          link: '/admin',
          label: 'admin',
          classes: ['admin-control']
        })
      }

      items.push({
        link: '/user/logout',
        label: 'log out'
      })

      return items
    }
  },
  created () {
    this.tbcoUserView = document.__TBCO__.userView
    this.isOrderMember = document.__TBCO__.isOrderMember
    this.isSiteAdmin = document.__TBCO__.isSiteAdmin
    this.sliderEdit = document.__TBCO__.sliderEdit
    this.futureDharmaDonation = document.__TBCO__.futureDharmaDonationBanner
    this.showBanner = document.__TBCO__.showBanner
    this.headerBannerVideo = document.__TBCO__.headerBannerVideoURL
    this.bannerTitle = document.__TBCO__.bannerTitle
    this.bannerSubtitle = document.__TBCO__.bannerSubtitle
    this.toggleButtonText = document.__TBCO__.toggleButtonText
    this.toggleLabelExpanded = document.__TBCO__.toggleButtonText2

    SiteHeaderMenuApi.loadItems(document.__TBCO__.siteHeaderMenu)
    document.__TBCO__.siteHeaderMenuApi = SiteHeaderMenuApi

    this.showDonateBtn = document.__TBCO__.showDonateBtn
    this.leftLinkText = document.__TBCO__.leftLinkText
    this.headerBannerLink = document.__TBCO__.headerBannerLink
    this.rightBannerLink = document.__TBCO__.rightBannerLink
    this.rightBannerLinkText = document.__TBCO__.rightBannerLinkText
  },
  methods: {
    donate() {
      if (document.__TBCO__.donationAction) {
        document.__TBCO__.donationAction()
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .dh-banner {
  p.banner__link-text {
    text-align: center;
  }
}
</style>
