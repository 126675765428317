import Vue from 'vue'

import SiteHeader from './SiteHeader.vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)

Vue.config.productionTip = false

new Vue({
  render: h => h(SiteHeader)
}).$mount('#site-header')
